<template>
  <div class="bg-white">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid custom-navbar">
        <router-link class="navbar-brand" to="/">
          <img src="@/assets/logo.webp" alt="Logo IPSSOMA" class="img-fluid" />
        </router-link>
      </div>
    </nav>

    <div class="container-fluid mt-5">
      <div class="row mt-5">
        <div class="col-md-11 offset-md-1 text-left">
          <h1 style="font-size: 2.7em; font-weight: bold">
            {{ mCourse.nombre }}
          </h1>
        </div>
        <div class="col-md-5 offset-md-1">
          <p style="font-size: 1em">{{ mCourse.descripcion }}</p>
          <button
            v-if="mode == 'public'"
            class="btn mt-3"
            style="
              border-radius: 15px;
              font-weight: 700;
              background-color: #ffc107;
            "
            @click="goToLogin()"
          >
            Regístrate o inicia sesión
          </button>
        </div>
        <div class="col-md-5 text-right">
          <img
            :src="mCourse.portada_url"
            style="max-width: 100%; width: 400px; height: auto"
          />
        </div>
        <div class="col-md-10 offset-md-1 text-center px-5">
          <p style="font-size: 1.3em">
            {{ mCourse.will_learn }}
          </p>
        </div>
      </div>
    </div>

    <!-- logros -->
    <div class="container-fluid bg-orange text-center px-5">
      <div class="row p-5 badges">
        <div class="col-md-4">
          <img
            src="@/assets/logro1.webp"
            alt="Aprende a prevenir la ocurrencia de accidentes de trabajo y
                    enfermedades ocupacionales."
          />
          <p>
            Aprende a prevenir la ocurrencia de accidentes de trabajo y
            enfermedades ocupacionales.
          </p>
        </div>
        <div class="col-md-4">
          <img
            src="@/assets/logro2.webp"
            alt="Domina la normativa legal vigente en materia de seguridad y salud en el trabajo."
          />
          <p>
            Domina la normativa legal vigente en materia de seguridad y salud en
            el trabajo.
          </p>
        </div>
        <div class="col-md-4">
          <img
            src="@/assets/logro4.webp"
            alt="Obtén un currículum de prevencionista ganador para conseguir tu primer empleo."
          />
          <p>
            Obtén un currículum de prevencionista ganador para conseguir tu
            primer empleo.
          </p>
        </div>
        <div class="col-md-4 offset-md-2">
          <img
            src="@/assets/logro3.webp"
            alt="Adquiere las competencias necesarias en materia de prevención, atención y recuperación ante emergencias que puedan presentarse en la empresa."
          />
          <p>
            Adquiere las competencias necesarias en materia de prevención,
            atención y recuperación ante emergencias que puedan presentarse en
            la empresa.
          </p>
        </div>
        <div class="col-md-4">
          <img
            src="@/assets/logro5.webp"
            alt="Realiza entrevistas de trabajo exitosas que te garantice un puesto de trabajo de calidad."
          />
          <p>
            Realiza entrevistas de trabajo exitosas que te garantice un puesto
            de trabajo de calidad.
          </p>
        </div>
      </div>
    </div>
    <!-- carrera profesional -->
    <div class="container-fluid text-center">
      <div class="row p-5">
        <div class="col-md-12">
          <h2>
            Comienza tu carrera profesional ahora - Curso Seguridad y Salud en
            el Trabajo
          </h2>
        </div>
        <div class="col-md-6 text-left">
          <p style="font-weight: 500">
            La Seguridad y Salud en el Trabajo es uno de los campos
            profesionales de mayor crecimiento del país, con amplias
            oportunidades laborales.
          </p>
          <p style="font-weight: 500">
            Todas las empresas necesitan cumplir con la normativa vigente, Ley
            29783 y sus modificatorias, para poder gestionar sus operaciones sin
            ser sancionados, por lo que cada día las empresas demandan más
            profesionales en el área.
          </p>
          <p style="font-weight: 500">
            La realidad es que hacer carrera como profesional en Seguridad y
            Salud en el Trabajo no es complicado.
          </p>
          <p style="font-weight: 500">
            No necesitas ser ingeniero o administrador industrial para comenzar
            a trabajar, tú puedes convertirte en el próximo prevencionista con
            este curso.
          </p>
          <p style="font-weight: 500">
            Pero no basta con ser cualquier prevencionista. Si quieres ser el
            mejor, necesitarás educación de calidad.
          </p>
        </div>
        <div class="col-md-6">
          <img
            src="@/assets/iniciar-curso.webp"
            alt="Prevencionista de riesgos conversando con su equipo de trabajo para ilustrar cómo se vería el graduado del diplomado SST cuando consiga su primer empleo"
            style="max-width: 500px; width: 100%; height: auto"
          />
        </div>
      </div>
    </div>

    <!-- metodología -->
    <div class="container-fluid text-center">
      <div class="row p-5">
        <!-- <div class="col-md-12"></div> -->
        <div class="col-md-6">
          <img
            src="@/assets/metodologia.webp"
            alt="persona enfrente de su computador estudiando como ejemplo de cómo se vería un estudiante del diplomado SST"
            style="max-width: 500px; width: 100%; height: auto"
          />
        </div>
        <div class="col-md-6 text-left">
          <h2 class="text-center">Rápida, flexible y de calidad</h2>
          <p style="font-weight: 500">
            Sabemos que no tienes tiempo y que quieres una educación práctica y
            flexible para poder llevar a cabo tus actividades diarias mientras
            creces profesionalmente.
          </p>
          <p style="font-weight: 500">
            Es por eso que desde IPSSOMA contamos con una metodología mixta para
            que puedas capacitarte de manera divertida, fácil y en el momento
            que lo desees.
          </p>
          <p style="font-weight: 500">
            Contarás con todo el material descargable y disponible las 24 horas
            del día. Además, podrás asistir de manera opcional a encuentros
            semanales vía Zoom con el tutor académico, para que puedas resolver
            todas tus dudas junto con tus compañeros de clase y aprendas de
            manera más interactiva.
          </p>
          <p style="font-weight: 500">
            De esta manera te garantizamos una educación flexible y de calidad,
            para que te proyectes en una carrera profesional con futuro.
          </p>
        </div>
      </div>
    </div>

    <div class="container-fluid my-5">
      <div class="">
        <h5 class="font-weight-bolder;">
          ¿Qué incluye el Curso Seguridad y Salud en Trabajo y Prevención de
          Riesgos Laborales?
        </h5>
        <p>
          Videoclases grabadas, lecturas descargables, casos prácticos y
          encuentros en vivo, en tiempo real, con el tutor académico vía zoom.
        </p>
        <span><strong>Conoce la malla curricular:</strong></span>
      </div>
      <div class="row">
        <div class="col-lg-12 col-sm-12 col-xs-12 col-md-12">
          <v-expansion-panels accordion class="mt-5">
            <v-expansion-panel
              v-for="(module, index) in course.modules"
              :key="index + 1"
            >
              <v-expansion-panel-header
                style="font-weight: bold; background-color: #ffc700"
              >
                Módulo {{ index + 1 }}. {{ module.name }}
              </v-expansion-panel-header>
              <v-expansion-panel-content class="text-justify">
                <ul class="list-unstyled">
                  <li
                    v-for="(less, subindex) in course.modules[index].lessons"
                    :key="subindex + 1"
                    class="my-4"
                  >
                    <div class="cursor-pointer" v-if="less.is_preview == 1">
                      <i
                        class="far fa-play-circle mr-3"
                        style="color: #ffc700; font-size: 1em"
                      ></i
                      >{{ index + 1 }}.{{ subindex + 1 }} {{ less.name }}
                    </div>
                    <div v-else>
                      <i class="fas fa-lock mr-3"></i>{{ index + 1 }}.{{
                        subindex + 1
                      }}
                      {{ less.name }}
                    </div>
                  </li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </div>

    <!-- profesores -->
    <div class="container-fluid text-center bg-orange">
      <div class="row teachers p-5">
        <div class="col-md-12 text-center">
          <h2>Ellos serán tus profesores</h2>
        </div>
        <div class="col-md-6">
          <img src="@/assets/profesor_1.webp" alt="Ing. Emilio López" />
          <h3>Ing. Emilio López</h3>
          <p>
            Profesional en Ingeniería Industrial, titulado, colegiado y
            habilitado. Reg. CIP nro. 200327 Especialista en prevención de
            riesgos laborales e higiene en el trabajo por la Universidad
            Nacional de Ingeniería
          </p>
        </div>
        <div class="col-md-6">
          <img src="@/assets/profesor_2.webp" alt="Ing. Jorge Pietro Mena" />
          <h3>Ing. Jorge Pietro Mena</h3>
          <p>
            Ingeniero Industrial (UPC) y Mgtr. en Administración de Empresas
            (UNMSM), con Certificación Trinorma, BASC, GREEN BELT. Experiencia
            de +10 años en operaciones logísticas e implementación de proyectos
            de mejora continua.
          </p>
        </div>
      </div>
    </div>

    <!-- oferta -->
    <div class="container-fluid">
      <div class="row d-flex justify-content-center">
        <div class="col-md-5">
          <v-card
            :loading="loading"
            class="mx-auto my-12"
            max-width="450"
            style="
              border-top-left-radius: 7%;
              border-top-right-radius: 7%;
              border-bottom-right-radius: 3%;
              border-bottom-left-radius: 3%;
              background-color: #343a40;
            "
          >
            <template slot="progress">
              <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
              ></v-progress-linear>
            </template>
            <v-img
              height="250"
              :src="require('@/assets/regalo1.webp')"
              alt="persona presionando un dispensador de antibacterial como portada del bonus que trae el Diplomado Seguridad y Salud en el Trabajo"
            ></v-img>

            <v-card-title style="color: #ffc107"
              >Bonus 1: Curso de Bioseguridad ante el Sars-Cov-2</v-card-title
            >

            <v-card-text style="color: #fff">
              <div>
                <p>
                  La Seguridad y Salud en el Trabajo ha cambiado desde el inicio
                  de la pandemia.
                </p>
                <p>
                  Aprende todas las recomendaciones para prevenir el contagio
                  del virus dentro y fuera de los espacios laborales con las
                  Nuevas Directrices ISO­/PAS 45005:2020 y Lineamientos del
                  MINSA.
                </p>
                <p style="color: #ffc107">
                  Incluye el certificado físico y envío por Olva Courrier 100%
                  gratuito
                </p>
              </div>
            </v-card-text>

            <div class="d-flex justify-content-center pb-5">
              <v-card-actions>
                <v-btn color="#ffc107" @click="reserve">
                  Incribirme ahora
                </v-btn>
              </v-card-actions>
            </div>
          </v-card>
        </div>
        <div class="col-md-5">
          <v-card
            :loading="loading"
            class="mx-auto my-12"
            max-width="450"
            style="
              border-top-left-radius: 7%;
              border-top-right-radius: 7%;
              border-bottom-right-radius: 3%;
              border-bottom-left-radius: 3%;
              background-color: #343a40;
            "
          >
            <template slot="progress">
              <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
              ></v-progress-linear>
            </template>
            <v-img
              height="250"
              :src="require('@/assets/regalo2.webp')"
              alt="persona agarrando de la mano a otra persona de manera profesional como portada del segundo bonus 'taller de empleabilidad' que trae el Diplomado de Seguridad y Salud en el Trabajo"
            ></v-img>

            <v-card-title style="color: #ffc107"
              >Bonus 2: Taller de empleabilidad</v-card-title
            >

            <v-card-text style="color: #fff">
              <div>
                <p>Aprende a elaborar un currículum ganador.</p>
                <p>Construye tu marca personal y posiciónate cómo experto.</p>
                <p>
                  Prepárate para las entrevistas de trabajos con técnicas
                  profesionales de oratoria y lenguaje corporal.
                </p>
                <p>
                  Conoce los nuevos retos del mundo laboral en Seguridad y Salud
                  en el Trabajo.
                </p>
                <p class="text-warning">
                  Incluye el certificado físico y envío por Olva Courrier 100%
                  gratuito
                </p>
              </div>
            </v-card-text>

            <div class="d-flex justify-content-center pb-5">
              <v-card-actions>
                <v-btn color="#ffc107" @click="reserve">
                  Incribirme ahora
                </v-btn>
              </v-card-actions>
            </div>
          </v-card>
        </div>
      </div>
    </div>

    <!-- conoce ipssoma -->
    <div class="container-fluid" style="max-height: 500px; overflow: hidden">
      <div class="row">
        <div
          class="col-md-6 px-5"
          style="
            background-color: #ffc107;
            text-justify: inter-word;
            text-align: justify;
            line-height: 1.7;
          "
        >
          <h2 class="py-5 text-center">Conoce el IPSSOMA</h2>
          <p style="font-weight: 600">
            Somos una institución nacida en el 2018, con modalidad E-learning,
            proponiendo una educación: rápida, flexible y de calidad, formando
            especialistas en el área de Seguridad y Salud en el Trabajo, Medio
            Ambiente y Calidad, con excelente plana docente y una malla
            curricular actualizada.
          </p>

          <p style="font-weight: 600">
            Desde sus inicios a la fecha, más de 5.000 alumnos han logrado
            capacitarse, certificarse y potenciar sus conocimientos con nuestros
            cursos y diplomados.
          </p>
          <div class="text-center mt-5">
            <v-btn
              to="/historia"
              rounded
              color="#343a40"
              dark
              style="font-weight: bolder; padding: 25px"
            >
              <div class="text-capitalize">Conoce </div>  <div class="text-lowercase pl-1">toda la historia detrás</div></v-btn
            >
          </div>
        </div>
        <div class="col-md-6" style="padding: 0px">
          <img
            src="@/assets/image-conoce.webp"
            alt="hombre y mujjer prevencionista con su casco de seguridad y su mascarilla quirúrgica posando ante la foto como parte del equipo de IPSSOMA"
          />
        </div>
      </div>
    </div>
    <!-- preguntas frecuentes -->
    <div class="container-fluid" style="margin-top: 50px">
      <div class="row">
        <div class="col-md-6 mx-auto">
          <img
            class="mx-auto d-block"
            style="max-width: 350px; width: 100%;"
            src="@/assets/preguntas-frecuentes.webp"
            alt="hombre y mujjer prevencionista con su casco de seguridad y su mascarilla quirúrgica posando ante la foto como parte del equipo de IPSSOMA"
          />
        </div>
        <div class="col-md-6">
          <v-expansion-panels dark accordion focusable>
            <v-expansion-panel v-for="item in frequent_question" :key="item.id">
              <v-expansion-panel-header
                style="background-color: #343a40; color: #fff"
              >
                {{ item.question }}</v-expansion-panel-header
              >
              <v-expansion-panel-content
                v-if="item.hasLink"
                class="py-2"
                style="background-color: #fff; color: #000"
              >
                <a :href="item.link">{{ item.answer }}</a>
              </v-expansion-panel-content>
              <v-expansion-panel-content
                v-else
                class="py-2"
                style="background-color: #fff; color: #000"
              >
                {{ item.answer }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </div>

    <div class="container-fluid mt-5" style="background-color: #ffc700">
      <div class="col-md-12">
        <h2 class="font-weight-bold py-5 text-center">
          Ha llegado el momento de potenciar tu condición profesional
        </h2>
        <v-card class="mx-auto" max-width="500" style="border-radius: 5%">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5 text-center">
                <i class="fas fa-box-open fa-1x"></i>
                Consigue un curso completo
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-card-text>
            <v-row align="center">
              <v-col cols="12" class="text-center">
                <v-row align="center">
                  <v-col cols="12" class="text-center">
                    <p
                      class="mx-auto text-center py-3"
                      style="
                        background-color: #ffc700;
                        max-width: 200px;
                        font-size: 1.3em;
                      "
                    >
                      Ahorra s/40,1 soles
                    </p>
                  </v-col>
                </v-row>

                <p
                  class="font-weight-bolder;"
                  style="font-size: 2.9em; color: #000; display: inline"
                >
                  s/479,9
                </p>
                <del style="font-size: 1.9em; color: #525252; display: inline"
                  >s/520</del
                >
                <div class="d-flex justify-content-center mt-5">
                  <v-card-actions>
                    <v-btn color="#ffc107" @click="reserve">
                      Incribirme ahora
                    </v-btn>
                  </v-card-actions>
                </div>
              </v-col>

              <v-col
                cols="12"
                class="text-center"
                style="background-color: #f8f9fa"
              >
                <p>
                  <v-icon color="#ffc700">mdi-laptop</v-icon>
                  Aprovecha y consigue acceso con un 30% de descuento por tiempo
                  limitado
                </p>
              </v-col>
            </v-row>
          </v-card-text>

          <v-list-item>
            <v-list-item-icon>
              <v-icon color="#ffc700">mdi-check-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle>6 módulos</v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="#ffc700">mdi-check-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle
              >Videoclases, material descargable</v-list-item-subtitle
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="#ffc700">mdi-check-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle
              >Asesorías en vivo vía zoom</v-list-item-subtitle
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="#ffc700">mdi-check-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle
              >Acompañamiento de la comunidad estudiantil</v-list-item-subtitle
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="#ffc700">mdi-check-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle
              >Bolsa de trabajo exclusiva</v-list-item-subtitle
            >
          </v-list-item>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
// import WatchIntro from "@/components/auth/course/video/watch-intro";
import { mapState, mapActions } from "vuex";
import CulqiCheckout from "vue-culqi-checkout";

export default {
  name: "Product",
  data() {
    return {
      frequent_question: [
        {
          id: 1,
          question:
            "¿Necesito tener una carrera o ser ingeniero para el Curso Seguridad y Salud en el Trabajo y Prevención de Riesgos Laborales?",
          answer:
            "No, no es necesario tener estudios previos. El curso está dirigido a: prevencionistas, supervisores, bachilleres y público en general que desee adquirir o actualizar conocimientos en el área de la Seguridad y Salud en el Trabajo.",
          hasLink: false,
        },
        {
          id: 2,
          question:
            "¿Puedo conseguir trabajo tan solo con sus certificaciones?",
          answer:
            "Tendrás todas las herramientas necesarias para que consigas un trabajo como profesional en Prevención de Riesgos, todo dependerá la aplicación de esas herramientas.",
          hasLink: false,
        },
        {
          id: 3,
          question: "¿Qué me garantiza que son una empresa confiable?",
          answer:
            "Somos una empresa jurídicamente constituida, con su RUC activo y orientada a la formación y capacitación profesional con más de cinco mil egresados a nivel nacional e internacional. <br> Instituto Peruano SSOMA <br> Con RUC 20608464094 <br> RNP OSCE: 21635770",
          hasLink: false,
        },
        {
          id: 4,
          question: "¿Cuál es el horario de estudio?",
          answer:
            "Tendrás acceso a las aulas las 24 horas del día durante todo el trayecto de tu formación. Además, contarás con una tutoría académica cada semana vía Zoom.",
          hasLink: false,
        },
        {
          id: 5,
          question: "¿Las certificaciones tienen fecha de vencimiento?",
          answer:
            "No, no tienen fecha de vencimiento. En caso de que una ley se actualice, será recomendable su actualización profesional. Sin embargo, el certificado seguirá siendo válido para la ley, reglamento o norma estudiada.",
          hasLink: false,
        },
        {
          id: 6,
          question: "¿Cuántas horas crédito tiene el diplomado sst?",
          answer:
            "Las horas créditos las calcula el ente rector donde te postulas para el puesto de trabajo, según el valor que él le asigne a la categoría 'diplomado'.",
          hasLink: false,
        },
        {
          id: 7,
          question: "¿Cuánto dura el curso?",
          answer:
            "La duración del Curso es de 3 meses. Y, tienes la oportunidad de adelantar si así lo deseas, realizando las actividades de evaluación en línea. Así solicitas la emisión y envío de tu diploma.",
          hasLink: false,
        },
        {
          id: 8,
          question: "¿Qué otros cursos ofrecen?",
          answer:
            "Contamos con mas de 30 cursos y diplomados, puedes revisar toda la oferta aquí.",
          hasLink: true,
          link: "https://ipssoma.com/#cursos",
        },
        {
          id: 9,
          question: "¿Donde están ubicados?",
          answer:
            "Av. La Fontana #440 - Centro Comercial “La Rotonda II” - Oficina 2086. La Molina, Lima - Perú",
          hasLink: true,
          link: "https://goo.gl/maps/gZEC8N3rxwbCrtYP7",
        },
      ],
      loading: false,
      slug: "",
      url: "",
      mCourse: {},
      mode: "",
      course_id: null,
      loadingRelated: true,
      isDetailsLoading: false,
      courseFilter: null,
      culqiConfig: {
        // publicKey: "pk_live_bb31092d20cc0fac",
        publicKey: "pk_test_cdbcffcc00366c42",
        title: "INSTITUTO PERUANO SSOMA",
        currency: "PEN",
        style: {
          logo: "https://www.ipssoma.com/images/s2.png",
          maincolor: "#ffbf00",
          buttontext: "#ffffff",
        },
      },
    };
  },
  computed: {
    ...mapState("course", ["course", "renderVideo", "isLoading"]),
  },
  methods: {
    reserve() {},
    goToLogin() {
      localStorage.removeItem("access_token");
      this.$router.push("/iniciar-sesion");
    },

    ...mapActions("course", {
      getCourse: "getCourse",
      getVideo: "getVideo",
      buyCourse: "buyCourse",
    }),

    async getAttributes() {
      const { data } = await this.$axios.get(
        `course/details/${this.$route.params.slug}`
      );
      this.mCourse = data;
      this.culqiConfig.amount = data.precio.toFixed(2).replace(".", "");
      this.culqiConfig.description = data.nombre;
      this.isDetailsLoading = true;
    },
  },
  // Buscar curso por slug
  watch: {
    $route: {
      handler: function () {
        this.slug = this.$route.params.slug;
        this.getAttributes();
        this.getCourse(this.slug);
      },
      immediate: true,
    },
    // slug() {
    //   this.getAttributes();
    // },
    // mCourse() {
    //   alert("hola");
    // },
  },
  created() {
    this.slug = this.$route.params.slug;
    this.mode = localStorage.getItem("mode");
    this.getAttributes();
    this.getCourse(this.slug);
    window.Vue.use(CulqiCheckout, this.culqiConfig);
    // this.getCourses();
  },
};
</script>

<style scoped>
.move-to-right {
  /* position: absolute; */
  height: 100%;
  width: 100%;
  right: -20px;
  bottom: -20px;
  z-index: 2;
  background: white;
  padding: 10px 20px;
}

.custom-navbar {
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.bg-orange {
  background-color: #ffc700;
}
.badges > div > p {
  font-weight: 700;
  font-size: 1.1em;
}
.badges > div > img {
  max-width: 150px;
}
.teachers > div > img {
  max-width: 150px;
  width: 150px;
}
.teachers > div > p {
  width: 100%;
}
/* .avatar-productor {
  width: 300px;
}
.container-fluid {
  width: 97%;
  margin-inline: auto;
  padding-bottom: 50px;
  background-image: none !important ;
}
.title-course {
  text-transform: uppercase;
}
.img-course {
  width: 100%;
  max-width: 900px;
  max-height: 427px;
  border-radius: 25px;
}
.img-card {
  width: 100%;
  border-radius: 25px 25px 0px 0;
  height: 50%;
}
@media (max-width: 700px) and (min-width: 577px) {
  .img-card {
    height: 40%;
  }
}
.img-productor {
  width: 100% !important;
  height: 100% !important;
  max-width: 100px;
  max-height: 100px;
}

.list-group {
  padding-left: 0;
  border-radius: 25px;
}
.video {
  width: 100%;
  margin: auto;
  height: 600px;
}

.puntuacion {
  font-size: 1.04rem;
}

.detalles p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 1800px) {
  .container-fluid {
    width: 76%;
  }
}

@media (max-width: 1500px) {
  .detalles {
    font-size: 10px;
  }
}
@media (max-width: 1200px) {
  .img-productor {
    width: 70% !important;
    height: 80% !important;
  }
}

@media (max-width: 991px) {
  .loader-img-course {
    display: none !important;
  }
  .img-course {
    display: none !important;
  }
  .detailsCourse {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    text-align: center !important;
    padding-right: 1rem !important;
  }
  .detailsCourse p {
    text-align: center !important;
  }
  p {
    padding-right: 0 !important ;
  }

  .cardCursos {
    width: 40% !important;
    flex: none !important;
  }
  .card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .detalles {
    font-size: 20px;
  }
}

@media (max-width: 576px) {
  .img-productor {
    width: 155% !important;
    margin-left: 0 !important;
  }
  .productor {
    display: flex !important;
    flex-direction: row !important;
  }

  .productor .image {
    width: 20% !important;
  }

  .productor .detalles {
    width: 80% !important;
  }

  .cardCursos {
    width: 100% !important;
    flex: none !important;
  }
}


.loader-titles {
  height: 40px;
  width: 100%;
}

.loader-descriptions {
  height: 50px;
}

.loader-text-small {
  height: 30px;
  width: 70%;
}

.loader-card {
  height: 300px;
}

.loader-temary {
  height: 800px;
}

.loader-img-productor {
  height: 100%;
  width: 100%;
}

.loader-img-course {
  height: 427px;
} */
</style>
